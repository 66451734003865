

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}


@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-sm,
  .gx-sm-sm {
    --bs-gutter-x: 0.3125rem;
  }
  .g-sm-sm,
  .gy-sm-sm {
    --bs-gutter-y: 0.3125rem;
  }
  .g-sm-3px,
  .gx-sm-3px {
    --bs-gutter-x: 3px;
  }
  .g-sm-3px,
  .gy-sm-3px {
    --bs-gutter-y: 3px;
  }
  .g-sm-15px,
  .gx-sm-15px {
    --bs-gutter-x: 15px;
  }
  .g-sm-15px,
  .gy-sm-15px {
    --bs-gutter-y: 15px;
  }
  .g-sm-25px,
  .gx-sm-25px {
    --bs-gutter-x: 25px;
  }
  .g-sm-25px,
  .gy-sm-25px {
    --bs-gutter-y: 25px;
  }
  .g-sm-35px,
  .gx-sm-35px {
    --bs-gutter-x: 35px;
  }
  .g-sm-35px,
  .gy-sm-35px {
    --bs-gutter-y: 35px;
  }
  .g-sm-65px,
  .gx-sm-65px {
    --bs-gutter-x: 65px;
  }
  .g-sm-65px,
  .gy-sm-65px {
    --bs-gutter-y: 65px;
  }
  .g-sm-74px,
  .gx-sm-74px {
    --bs-gutter-x: 74px;
  }
  .g-sm-74px,
  .gy-sm-74px {
    --bs-gutter-y: 74px;
  }
  .g-sm-700,
  .gx-sm-700 {
    --bs-gutter-x: 700px;
  }
  .g-sm-700,
  .gy-sm-700 {
    --bs-gutter-y: 700px;
  }
  .g-sm-n15,
  .gx-sm-n15 {
    --bs-gutter-x: -15px;
  }
  .g-sm-n15,
  .gy-sm-n15 {
    --bs-gutter-y: -15px;
  }
  .g-sm-n1,
  .gx-sm-n1 {
    --bs-gutter-x: -10px;
  }
  .g-sm-n1,
  .gy-sm-n1 {
    --bs-gutter-y: -10px;
  }
  .g-sm-n5,
  .gx-sm-n5 {
    --bs-gutter-x: -5px;
  }
  .g-sm-n5,
  .gy-sm-n5 {
    --bs-gutter-y: -5px;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.625rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.625rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3.125rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3.125rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 3.75rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 3.75rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 4.375rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 4.375rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 5.625rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 5.625rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 6.25rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 6.25rem;
  }
  .g-sm-gutter,
  .gx-sm-gutter {
    --bs-gutter-x: 2.133rem;
  }
  .g-sm-gutter,
  .gy-sm-gutter {
    --bs-gutter-y: 2.133rem;
  }
  .g-sm-out-container,
  .gx-sm-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }
  .g-sm-out-container,
  .gy-sm-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-sm,
  .gx-md-sm {
    --bs-gutter-x: 0.3125rem;
  }
  .g-md-sm,
  .gy-md-sm {
    --bs-gutter-y: 0.3125rem;
  }
  .g-md-3px,
  .gx-md-3px {
    --bs-gutter-x: 3px;
  }
  .g-md-3px,
  .gy-md-3px {
    --bs-gutter-y: 3px;
  }
  .g-md-15px,
  .gx-md-15px {
    --bs-gutter-x: 15px;
  }
  .g-md-15px,
  .gy-md-15px {
    --bs-gutter-y: 15px;
  }
  .g-md-25px,
  .gx-md-25px {
    --bs-gutter-x: 25px;
  }
  .g-md-25px,
  .gy-md-25px {
    --bs-gutter-y: 25px;
  }
  .g-md-35px,
  .gx-md-35px {
    --bs-gutter-x: 35px;
  }
  .g-md-35px,
  .gy-md-35px {
    --bs-gutter-y: 35px;
  }
  .g-md-65px,
  .gx-md-65px {
    --bs-gutter-x: 65px;
  }
  .g-md-65px,
  .gy-md-65px {
    --bs-gutter-y: 65px;
  }
  .g-md-74px,
  .gx-md-74px {
    --bs-gutter-x: 74px;
  }
  .g-md-74px,
  .gy-md-74px {
    --bs-gutter-y: 74px;
  }
  .g-md-700,
  .gx-md-700 {
    --bs-gutter-x: 700px;
  }
  .g-md-700,
  .gy-md-700 {
    --bs-gutter-y: 700px;
  }
  .g-md-n15,
  .gx-md-n15 {
    --bs-gutter-x: -15px;
  }
  .g-md-n15,
  .gy-md-n15 {
    --bs-gutter-y: -15px;
  }
  .g-md-n1,
  .gx-md-n1 {
    --bs-gutter-x: -10px;
  }
  .g-md-n1,
  .gy-md-n1 {
    --bs-gutter-y: -10px;
  }
  .g-md-n5,
  .gx-md-n5 {
    --bs-gutter-x: -5px;
  }
  .g-md-n5,
  .gy-md-n5 {
    --bs-gutter-y: -5px;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.625rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.625rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3.125rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3.125rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 3.75rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 3.75rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 4.375rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 4.375rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 5rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 5.625rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 5.625rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 6.25rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 6.25rem;
  }
  .g-md-gutter,
  .gx-md-gutter {
    --bs-gutter-x: 2.133rem;
  }
  .g-md-gutter,
  .gy-md-gutter {
    --bs-gutter-y: 2.133rem;
  }
  .g-md-out-container,
  .gx-md-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }
  .g-md-out-container,
  .gy-md-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}


@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .m-sm-sm {
    margin: 0.3125rem !important;
  }
  .m-sm-3px {
    margin: 3px !important;
  }
  .m-sm-15px {
    margin: 15px !important;
  }
  .m-sm-25px {
    margin: 25px !important;
  }
  .m-sm-35px {
    margin: 35px !important;
  }
  .m-sm-65px {
    margin: 65px !important;
  }
  .m-sm-74px {
    margin: 74px !important;
  }
  .m-sm-700 {
    margin: 700px !important;
  }
  .m-sm-n15 {
    margin: -15px !important;
  }
  .m-sm-n1 {
    margin: -10px !important;
  }
  .m-sm-n5 {
    margin: -5px !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.625rem !important;
  }
  .m-sm-2 {
    margin: 1.25rem !important;
  }
  .m-sm-3 {
    margin: 1.875rem !important;
  }
  .m-sm-4 {
    margin: 2.5rem !important;
  }
  .m-sm-5 {
    margin: 3.125rem !important;
  }
  .m-sm-6 {
    margin: 3.75rem !important;
  }
  .m-sm-7 {
    margin: 4.375rem !important;
  }
  .m-sm-8 {
    margin: 5rem !important;
  }
  .m-sm-9 {
    margin: 5.625rem !important;
  }
  .m-sm-10 {
    margin: 6.25rem !important;
  }
  .m-sm-gutter {
    margin: 2.133rem !important;
  }
  .m-sm-out-container {
    margin: calc(50% - 50vw) !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-sm {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-sm-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-sm-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-sm-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-sm-35px {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }
  .mx-sm-65px {
    margin-right: 65px !important;
    margin-left: 65px !important;
  }
  .mx-sm-74px {
    margin-right: 74px !important;
    margin-left: 74px !important;
  }
  .mx-sm-700 {
    margin-right: 700px !important;
    margin-left: 700px !important;
  }
  .mx-sm-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-sm-n1 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .mx-sm-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-sm-2 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-3 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-4 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-sm-6 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-sm-7 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-sm-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-9 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-sm-10 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-sm-gutter {
    margin-right: 2.133rem !important;
    margin-left: 2.133rem !important;
  }
  .mx-sm-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-sm {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-sm-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-sm-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-sm-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-sm-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .my-sm-65px {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .my-sm-74px {
    margin-top: 74px !important;
    margin-bottom: 74px !important;
  }
  .my-sm-700 {
    margin-top: 700px !important;
    margin-bottom: 700px !important;
  }
  .my-sm-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-sm-n1 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .my-sm-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-sm-2 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-3 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-sm-6 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-sm-7 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-sm-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-9 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-sm-10 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-sm-gutter {
    margin-top: 2.133rem !important;
    margin-bottom: 2.133rem !important;
  }
  .my-sm-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-sm {
    margin-top: 0.3125rem !important;
  }
  .mt-sm-3px {
    margin-top: 3px !important;
  }
  .mt-sm-15px {
    margin-top: 15px !important;
  }
  .mt-sm-25px {
    margin-top: 25px !important;
  }
  .mt-sm-35px {
    margin-top: 35px !important;
  }
  .mt-sm-65px {
    margin-top: 65px !important;
  }
  .mt-sm-74px {
    margin-top: 74px !important;
  }
  .mt-sm-700 {
    margin-top: 700px !important;
  }
  .mt-sm-n15 {
    margin-top: -15px !important;
  }
  .mt-sm-n1 {
    margin-top: -10px !important;
  }
  .mt-sm-n5 {
    margin-top: -5px !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.625rem !important;
  }
  .mt-sm-2 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-3 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-4 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3.125rem !important;
  }
  .mt-sm-6 {
    margin-top: 3.75rem !important;
  }
  .mt-sm-7 {
    margin-top: 4.375rem !important;
  }
  .mt-sm-8 {
    margin-top: 5rem !important;
  }
  .mt-sm-9 {
    margin-top: 5.625rem !important;
  }
  .mt-sm-10 {
    margin-top: 6.25rem !important;
  }
  .mt-sm-gutter {
    margin-top: 2.133rem !important;
  }
  .mt-sm-out-container {
    margin-top: calc(50% - 50vw) !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-sm {
    margin-right: 0.3125rem !important;
  }
  .me-sm-3px {
    margin-right: 3px !important;
  }
  .me-sm-15px {
    margin-right: 15px !important;
  }
  .me-sm-25px {
    margin-right: 25px !important;
  }
  .me-sm-35px {
    margin-right: 35px !important;
  }
  .me-sm-65px {
    margin-right: 65px !important;
  }
  .me-sm-74px {
    margin-right: 74px !important;
  }
  .me-sm-700 {
    margin-right: 700px !important;
  }
  .me-sm-n15 {
    margin-right: -15px !important;
  }
  .me-sm-n1 {
    margin-right: -10px !important;
  }
  .me-sm-n5 {
    margin-right: -5px !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.625rem !important;
  }
  .me-sm-2 {
    margin-right: 1.25rem !important;
  }
  .me-sm-3 {
    margin-right: 1.875rem !important;
  }
  .me-sm-4 {
    margin-right: 2.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3.125rem !important;
  }
  .me-sm-6 {
    margin-right: 3.75rem !important;
  }
  .me-sm-7 {
    margin-right: 4.375rem !important;
  }
  .me-sm-8 {
    margin-right: 5rem !important;
  }
  .me-sm-9 {
    margin-right: 5.625rem !important;
  }
  .me-sm-10 {
    margin-right: 6.25rem !important;
  }
  .me-sm-gutter {
    margin-right: 2.133rem !important;
  }
  .me-sm-out-container {
    margin-right: calc(50% - 50vw) !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-sm {
    margin-bottom: 0.3125rem !important;
  }
  .mb-sm-3px {
    margin-bottom: 3px !important;
  }
  .mb-sm-15px {
    margin-bottom: 15px !important;
  }
  .mb-sm-25px {
    margin-bottom: 25px !important;
  }
  .mb-sm-35px {
    margin-bottom: 35px !important;
  }
  .mb-sm-65px {
    margin-bottom: 65px !important;
  }
  .mb-sm-74px {
    margin-bottom: 74px !important;
  }
  .mb-sm-700 {
    margin-bottom: 700px !important;
  }
  .mb-sm-n15 {
    margin-bottom: -15px !important;
  }
  .mb-sm-n1 {
    margin-bottom: -10px !important;
  }
  .mb-sm-n5 {
    margin-bottom: -5px !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.625rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3.125rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 3.75rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 4.375rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 5.625rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 6.25rem !important;
  }
  .mb-sm-gutter {
    margin-bottom: 2.133rem !important;
  }
  .mb-sm-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-sm {
    margin-left: 0.3125rem !important;
  }
  .ms-sm-3px {
    margin-left: 3px !important;
  }
  .ms-sm-15px {
    margin-left: 15px !important;
  }
  .ms-sm-25px {
    margin-left: 25px !important;
  }
  .ms-sm-35px {
    margin-left: 35px !important;
  }
  .ms-sm-65px {
    margin-left: 65px !important;
  }
  .ms-sm-74px {
    margin-left: 74px !important;
  }
  .ms-sm-700 {
    margin-left: 700px !important;
  }
  .ms-sm-n15 {
    margin-left: -15px !important;
  }
  .ms-sm-n1 {
    margin-left: -10px !important;
  }
  .ms-sm-n5 {
    margin-left: -5px !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.625rem !important;
  }
  .ms-sm-2 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-3 {
    margin-left: 1.875rem !important;
  }
  .ms-sm-4 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3.125rem !important;
  }
  .ms-sm-6 {
    margin-left: 3.75rem !important;
  }
  .ms-sm-7 {
    margin-left: 4.375rem !important;
  }
  .ms-sm-8 {
    margin-left: 5rem !important;
  }
  .ms-sm-9 {
    margin-left: 5.625rem !important;
  }
  .ms-sm-10 {
    margin-left: 6.25rem !important;
  }
  .ms-sm-gutter {
    margin-left: 2.133rem !important;
  }
  .ms-sm-out-container {
    margin-left: calc(50% - 50vw) !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-sm {
    padding: 0.3125rem !important;
  }
  .p-sm-3px {
    padding: 3px !important;
  }
  .p-sm-15px {
    padding: 15px !important;
  }
  .p-sm-25px {
    padding: 25px !important;
  }
  .p-sm-35px {
    padding: 35px !important;
  }
  .p-sm-65px {
    padding: 65px !important;
  }
  .p-sm-74px {
    padding: 74px !important;
  }
  .p-sm-700 {
    padding: 700px !important;
  }
  .p-sm-n15 {
    padding: -15px !important;
  }
  .p-sm-n1 {
    padding: -10px !important;
  }
  .p-sm-n5 {
    padding: -5px !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.625rem !important;
  }
  .p-sm-2 {
    padding: 1.25rem !important;
  }
  .p-sm-3 {
    padding: 1.875rem !important;
  }
  .p-sm-4 {
    padding: 2.5rem !important;
  }
  .p-sm-5 {
    padding: 3.125rem !important;
  }
  .p-sm-6 {
    padding: 3.75rem !important;
  }
  .p-sm-7 {
    padding: 4.375rem !important;
  }
  .p-sm-8 {
    padding: 5rem !important;
  }
  .p-sm-9 {
    padding: 5.625rem !important;
  }
  .p-sm-10 {
    padding: 6.25rem !important;
  }
  .p-sm-gutter {
    padding: 2.133rem !important;
  }
  .p-sm-out-container {
    padding: calc(50% - 50vw) !important;
  }
  .px-sm-sm {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-sm-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-sm-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-sm-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-sm-35px {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
  .px-sm-65px {
    padding-right: 65px !important;
    padding-left: 65px !important;
  }
  .px-sm-74px {
    padding-right: 74px !important;
    padding-left: 74px !important;
  }
  .px-sm-700 {
    padding-right: 700px !important;
    padding-left: 700px !important;
  }
  .px-sm-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }
  .px-sm-n1 {
    padding-right: -10px !important;
    padding-left: -10px !important;
  }
  .px-sm-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-sm-2 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-3 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-4 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-sm-6 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-sm-7 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-sm-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-9 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-sm-10 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-sm-gutter {
    padding-right: 2.133rem !important;
    padding-left: 2.133rem !important;
  }
  .px-sm-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }
  .py-sm-sm {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-sm-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-sm-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-sm-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-sm-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .py-sm-65px {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .py-sm-74px {
    padding-top: 74px !important;
    padding-bottom: 74px !important;
  }
  .py-sm-700 {
    padding-top: 700px !important;
    padding-bottom: 700px !important;
  }
  .py-sm-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }
  .py-sm-n1 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .py-sm-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-sm-2 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-3 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-sm-6 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-sm-7 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-sm-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-9 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-sm-10 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-sm-gutter {
    padding-top: 2.133rem !important;
    padding-bottom: 2.133rem !important;
  }
  .py-sm-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }
  .pt-sm-sm {
    padding-top: 0.3125rem !important;
  }
  .pt-sm-3px {
    padding-top: 3px !important;
  }
  .pt-sm-15px {
    padding-top: 15px !important;
  }
  .pt-sm-25px {
    padding-top: 25px !important;
  }
  .pt-sm-35px {
    padding-top: 35px !important;
  }
  .pt-sm-65px {
    padding-top: 65px !important;
  }
  .pt-sm-74px {
    padding-top: 74px !important;
  }
  .pt-sm-700 {
    padding-top: 700px !important;
  }
  .pt-sm-n15 {
    padding-top: -15px !important;
  }
  .pt-sm-n1 {
    padding-top: -10px !important;
  }
  .pt-sm-n5 {
    padding-top: -5px !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.625rem !important;
  }
  .pt-sm-2 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-3 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-4 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3.125rem !important;
  }
  .pt-sm-6 {
    padding-top: 3.75rem !important;
  }
  .pt-sm-7 {
    padding-top: 4.375rem !important;
  }
  .pt-sm-8 {
    padding-top: 5rem !important;
  }
  .pt-sm-9 {
    padding-top: 5.625rem !important;
  }
  .pt-sm-10 {
    padding-top: 6.25rem !important;
  }
  .pt-sm-gutter {
    padding-top: 2.133rem !important;
  }
  .pt-sm-out-container {
    padding-top: calc(50% - 50vw) !important;
  }
  .pe-sm-sm {
    padding-right: 0.3125rem !important;
  }
  .pe-sm-3px {
    padding-right: 3px !important;
  }
  .pe-sm-15px {
    padding-right: 15px !important;
  }
  .pe-sm-25px {
    padding-right: 25px !important;
  }
  .pe-sm-35px {
    padding-right: 35px !important;
  }
  .pe-sm-65px {
    padding-right: 65px !important;
  }
  .pe-sm-74px {
    padding-right: 74px !important;
  }
  .pe-sm-700 {
    padding-right: 700px !important;
  }
  .pe-sm-n15 {
    padding-right: -15px !important;
  }
  .pe-sm-n1 {
    padding-right: -10px !important;
  }
  .pe-sm-n5 {
    padding-right: -5px !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.625rem !important;
  }
  .pe-sm-2 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-3 {
    padding-right: 1.875rem !important;
  }
  .pe-sm-4 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3.125rem !important;
  }
  .pe-sm-6 {
    padding-right: 3.75rem !important;
  }
  .pe-sm-7 {
    padding-right: 4.375rem !important;
  }
  .pe-sm-8 {
    padding-right: 5rem !important;
  }
  .pe-sm-9 {
    padding-right: 5.625rem !important;
  }
  .pe-sm-10 {
    padding-right: 6.25rem !important;
  }
  .pe-sm-gutter {
    padding-right: 2.133rem !important;
  }
  .pe-sm-out-container {
    padding-right: calc(50% - 50vw) !important;
  }
  .pb-sm-sm {
    padding-bottom: 0.3125rem !important;
  }
  .pb-sm-3px {
    padding-bottom: 3px !important;
  }
  .pb-sm-15px {
    padding-bottom: 15px !important;
  }
  .pb-sm-25px {
    padding-bottom: 25px !important;
  }
  .pb-sm-35px {
    padding-bottom: 35px !important;
  }
  .pb-sm-65px {
    padding-bottom: 65px !important;
  }
  .pb-sm-74px {
    padding-bottom: 74px !important;
  }
  .pb-sm-700 {
    padding-bottom: 700px !important;
  }
  .pb-sm-n15 {
    padding-bottom: -15px !important;
  }
  .pb-sm-n1 {
    padding-bottom: -10px !important;
  }
  .pb-sm-n5 {
    padding-bottom: -5px !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.625rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3.125rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 3.75rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 4.375rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 5.625rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 6.25rem !important;
  }
  .pb-sm-gutter {
    padding-bottom: 2.133rem !important;
  }
  .pb-sm-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }
  .ps-sm-sm {
    padding-left: 0.3125rem !important;
  }
  .ps-sm-3px {
    padding-left: 3px !important;
  }
  .ps-sm-15px {
    padding-left: 15px !important;
  }
  .ps-sm-25px {
    padding-left: 25px !important;
  }
  .ps-sm-35px {
    padding-left: 35px !important;
  }
  .ps-sm-65px {
    padding-left: 65px !important;
  }
  .ps-sm-74px {
    padding-left: 74px !important;
  }
  .ps-sm-700 {
    padding-left: 700px !important;
  }
  .ps-sm-n15 {
    padding-left: -15px !important;
  }
  .ps-sm-n1 {
    padding-left: -10px !important;
  }
  .ps-sm-n5 {
    padding-left: -5px !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.625rem !important;
  }
  .ps-sm-2 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-3 {
    padding-left: 1.875rem !important;
  }
  .ps-sm-4 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3.125rem !important;
  }
  .ps-sm-6 {
    padding-left: 3.75rem !important;
  }
  .ps-sm-7 {
    padding-left: 4.375rem !important;
  }
  .ps-sm-8 {
    padding-left: 5rem !important;
  }
  .ps-sm-9 {
    padding-left: 5.625rem !important;
  }
  .ps-sm-10 {
    padding-left: 6.25rem !important;
  }
  .ps-sm-gutter {
    padding-left: 2.133rem !important;
  }
  .ps-sm-out-container {
    padding-left: calc(50% - 50vw) !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-sm {
    gap: 0.3125rem !important;
  }
  .gap-sm-3px {
    gap: 3px !important;
  }
  .gap-sm-15px {
    gap: 15px !important;
  }
  .gap-sm-25px {
    gap: 25px !important;
  }
  .gap-sm-35px {
    gap: 35px !important;
  }
  .gap-sm-65px {
    gap: 65px !important;
  }
  .gap-sm-74px {
    gap: 74px !important;
  }
  .gap-sm-700 {
    gap: 700px !important;
  }
  .gap-sm-n15 {
    gap: -15px !important;
  }
  .gap-sm-n1 {
    gap: -10px !important;
  }
  .gap-sm-n5 {
    gap: -5px !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.625rem !important;
  }
  .gap-sm-2 {
    gap: 1.25rem !important;
  }
  .gap-sm-3 {
    gap: 1.875rem !important;
  }
  .gap-sm-4 {
    gap: 2.5rem !important;
  }
  .gap-sm-5 {
    gap: 3.125rem !important;
  }
  .gap-sm-6 {
    gap: 3.75rem !important;
  }
  .gap-sm-7 {
    gap: 4.375rem !important;
  }
  .gap-sm-8 {
    gap: 5rem !important;
  }
  .gap-sm-9 {
    gap: 5.625rem !important;
  }
  .gap-sm-10 {
    gap: 6.25rem !important;
  }
  .gap-sm-gutter {
    gap: 2.133rem !important;
  }
  .gap-sm-out-container {
    gap: calc(50% - 50vw) !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .vh-sm-30 {
    height: 30vh !important;
  }
  .vh-sm-40 {
    height: 40vh !important;
  }
  .vh-sm-50 {
    height: 50vh !important;
  }
  .vh-sm-60 {
    height: 60vh !important;
  }
  .vh-sm-65 {
    height: 65vh !important;
  }
  .vh-sm-70 {
    height: 70vh !important;
  }
  .vh-sm-80 {
    height: 80vh !important;
  }
  .vh-sm-100 {
    height: 100vh !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-70 {
    width: 70% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-85 {
    width: 85% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-20px {
    width: 20px !important;
  }
  .w-sm-80px {
    width: 80px !important;
  }
  .w-sm-180 {
    width: 180px !important;
  }
  .w-sm-200px {
    width: 200px !important;
  }
  .w-sm-cal200 {
    width: calc(100% - 200px) !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-20px {
    height: 20px !important;
  }
  .h-sm-27px {
    height: 27px !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .h-sm-100vh {
    height: 100vh !important;
  }
  .h-sm-300px {
    height: 300px !important;
  }
  .h-sm-400px {
    height: 400px !important;
  }
  .h-sm-600px {
    height: 600px !important;
  }
  .h-sm-750px {
    height: 750px !important;
  }
  .fs-sm-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-sm-2 {
    font-size: calc(1.34375rem + 1.125vw) !important;
  }
  .fs-sm-3 {
    font-size: calc(1.2875rem + 0.45vw) !important;
  }
  .fs-sm-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-sm-5 {
    font-size: calc(1.2625rem + 0.15vw) !important;
  }
  .fs-sm-6 {
    font-size: 1.125rem !important;
  }
  .fs-sm-7 {
    font-size: 1rem !important;
  }
  .fs-sm-xl {
    font-size: calc(1.5rem + 3vw) !important;
  }
  .fs-sm-lg {
    font-size: calc(1.3125rem + 0.75vw) !important;
  }
  .fs-sm-md {
    font-size: calc(1.35rem + 1.2vw) !important;
  }
  .fs-sm-sm {
    font-size: 0.875rem !important;
  }
  .fs-sm-xs {
    font-size: 0.75rem !important;
  }
  .fs-sm-xxs {
    font-size: 0.625rem !important;
  }
  .fs-sm-15 {
    font-size: 0.9375rem !important;
  }
  .fs-sm-12 {
    font-size: 0.75rem !important;
  }
  .fs-sm-25 {
    font-size: calc(1.28125rem + 0.375vw) !important;
  }
  .fs-sm-30 {
    font-size: calc(1.3125rem + 0.75vw) !important;
  }
  .fs-sm-55 {
    font-size: calc(1.46875rem + 2.625vw) !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .order-sm-unset {
    order: unset !important;
  }
  .cursor-sm-auto {
    cursor: auto !important;
  }
  .cursor-sm-pointer {
    cursor: pointer !important;
  }
  .cursor-sm-grab {
    cursor: grab !important;
  }
  .border-sm {
    border: 1px solid #5a5a5a !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-top-sm {
    border-top: 1px solid #5a5a5a !important;
  }
  .border-top-sm-0 {
    border-top: 0 !important;
  }
  .border-start-sm {
    border-left: 1px solid #5a5a5a !important;
  }
  .border-start-sm-0 {
    border-left: 0 !important;
  }
  .border-end-sm {
    border-right: 1px solid #5a5a5a !important;
  }
  .border-end-sm-0 {
    border-right: 0 !important;
  }
  .border-bottom-sm {
    border-bottom: 1px solid #5a5a5a !important;
  }
  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
  .shadow-sm {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16) !important;
  }
  .shadow-sm-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-sm-lg {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
  }
  .shadow-sm-none {
    box-shadow: none !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .text-decoration-sm-none {
    text-decoration: none !important;
  }
  .text-decoration-sm-underline {
    text-decoration: underline !important;
  }
  .text-decoration-sm-line-through {
    text-decoration: line-through !important;
  }
  .text-sm-lowercase {
    text-transform: lowercase !important;
  }
  .text-sm-uppercase {
    text-transform: uppercase !important;
  }
  .text-sm-capitalize {
    text-transform: capitalize !important;
  }
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
  .top-sm-0 {
    top: 0 !important;
  }
  .top-sm-25 {
    top: 25% !important;
  }
  .top-sm-50 {
    top: 50% !important;
  }
  .top-sm-75 {
    top: 75% !important;
  }
  .top-sm-100 {
    top: 100% !important;
  }
  .top-sm-10 {
    top: 10px !important;
  }
  .top-sm-20 {
    top: 20px !important;
  }
  .top-sm-30 {
    top: 30px !important;
  }
  .top-sm-n10 {
    top: -10px !important;
  }
  .top-sm-n20 {
    top: -20px !important;
  }
  .top-sm-n8 {
    top: -8px !important;
  }
  .top-sm-7 {
    top: 7px !important;
  }
  .top-sm-13 {
    top: 13px !important;
  }
  .top-sm-15 {
    top: 15px !important;
  }
  .top-sm-n3 {
    top: -3px !important;
  }
  .top-sm-5 {
    top: 5px !important;
  }
  .top-sm-2 {
    top: 2px !important;
  }
  .bottom-sm-0 {
    bottom: 0 !important;
  }
  .bottom-sm-25 {
    bottom: 25% !important;
  }
  .bottom-sm-50 {
    bottom: 50% !important;
  }
  .bottom-sm-75 {
    bottom: 75% !important;
  }
  .bottom-sm-100 {
    bottom: 100% !important;
  }
  .bottom-sm-10 {
    bottom: 10px !important;
  }
  .bottom-sm-20 {
    bottom: 20px !important;
  }
  .bottom-sm-30 {
    bottom: 30px !important;
  }
  .bottom-sm-n10 {
    bottom: -10px !important;
  }
  .bottom-sm-n20 {
    bottom: -20px !important;
  }
  .bottom-sm-n8 {
    bottom: -8px !important;
  }
  .bottom-sm-7 {
    bottom: 7px !important;
  }
  .bottom-sm-13 {
    bottom: 13px !important;
  }
  .bottom-sm-15 {
    bottom: 15px !important;
  }
  .bottom-sm-n3 {
    bottom: -3px !important;
  }
  .bottom-sm-5 {
    bottom: 5px !important;
  }
  .bottom-sm-2 {
    bottom: 2px !important;
  }
  .end-sm-0 {
    right: 0 !important;
  }
  .end-sm-25 {
    right: 25% !important;
  }
  .end-sm-50 {
    right: 50% !important;
  }
  .end-sm-75 {
    right: 75% !important;
  }
  .end-sm-100 {
    right: 100% !important;
  }
  .end-sm-10 {
    right: 10px !important;
  }
  .end-sm-20 {
    right: 20px !important;
  }
  .end-sm-30 {
    right: 30px !important;
  }
  .end-sm-n10 {
    right: -10px !important;
  }
  .end-sm-n20 {
    right: -20px !important;
  }
  .end-sm-n8 {
    right: -8px !important;
  }
  .end-sm-7 {
    right: 7px !important;
  }
  .end-sm-13 {
    right: 13px !important;
  }
  .end-sm-15 {
    right: 15px !important;
  }
  .end-sm-n3 {
    right: -3px !important;
  }
  .end-sm-5 {
    right: 5px !important;
  }
  .end-sm-2 {
    right: 2px !important;
  }
  .translate-middle-sm {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-sm-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-sm-y {
    transform: translateY(-50%) !important;
  }
}

@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .m-md-sm {
    margin: 0.3125rem !important;
  }
  .m-md-3px {
    margin: 3px !important;
  }
  .m-md-15px {
    margin: 15px !important;
  }
  .m-md-25px {
    margin: 25px !important;
  }
  .m-md-35px {
    margin: 35px !important;
  }
  .m-md-65px {
    margin: 65px !important;
  }
  .m-md-74px {
    margin: 74px !important;
  }
  .m-md-700 {
    margin: 700px !important;
  }
  .m-md-n15 {
    margin: -15px !important;
  }
  .m-md-n1 {
    margin: -10px !important;
  }
  .m-md-n5 {
    margin: -5px !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.625rem !important;
  }
  .m-md-2 {
    margin: 1.25rem !important;
  }
  .m-md-3 {
    margin: 1.875rem !important;
  }
  .m-md-4 {
    margin: 2.5rem !important;
  }
  .m-md-5 {
    margin: 3.125rem !important;
  }
  .m-md-6 {
    margin: 3.75rem !important;
  }
  .m-md-7 {
    margin: 4.375rem !important;
  }
  .m-md-8 {
    margin: 5rem !important;
  }
  .m-md-9 {
    margin: 5.625rem !important;
  }
  .m-md-10 {
    margin: 6.25rem !important;
  }
  .m-md-gutter {
    margin: 2.133rem !important;
  }
  .m-md-out-container {
    margin: calc(50% - 50vw) !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-sm {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-md-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-md-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-md-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-md-35px {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }
  .mx-md-65px {
    margin-right: 65px !important;
    margin-left: 65px !important;
  }
  .mx-md-74px {
    margin-right: 74px !important;
    margin-left: 74px !important;
  }
  .mx-md-700 {
    margin-right: 700px !important;
    margin-left: 700px !important;
  }
  .mx-md-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-md-n1 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .mx-md-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-md-2 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-3 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-4 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-md-6 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-md-7 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-md-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-9 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-md-10 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-md-gutter {
    margin-right: 2.133rem !important;
    margin-left: 2.133rem !important;
  }
  .mx-md-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-sm {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-md-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-md-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-md-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-md-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .my-md-65px {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .my-md-74px {
    margin-top: 74px !important;
    margin-bottom: 74px !important;
  }
  .my-md-700 {
    margin-top: 700px !important;
    margin-bottom: 700px !important;
  }
  .my-md-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-md-n1 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .my-md-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-md-2 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-3 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-5 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-md-6 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-md-7 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-md-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-9 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-md-10 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-md-gutter {
    margin-top: 2.133rem !important;
    margin-bottom: 2.133rem !important;
  }
  .my-md-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-sm {
    margin-top: 0.3125rem !important;
  }
  .mt-md-3px {
    margin-top: 3px !important;
  }
  .mt-md-15px {
    margin-top: 15px !important;
  }
  .mt-md-25px {
    margin-top: 25px !important;
  }
  .mt-md-35px {
    margin-top: 35px !important;
  }
  .mt-md-65px {
    margin-top: 65px !important;
  }
  .mt-md-74px {
    margin-top: 74px !important;
  }
  .mt-md-700 {
    margin-top: 700px !important;
  }
  .mt-md-n15 {
    margin-top: -15px !important;
  }
  .mt-md-n1 {
    margin-top: -10px !important;
  }
  .mt-md-n5 {
    margin-top: -5px !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.625rem !important;
  }
  .mt-md-2 {
    margin-top: 1.25rem !important;
  }
  .mt-md-3 {
    margin-top: 1.875rem !important;
  }
  .mt-md-4 {
    margin-top: 2.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3.125rem !important;
  }
  .mt-md-6 {
    margin-top: 3.75rem !important;
  }
  .mt-md-7 {
    margin-top: 4.375rem !important;
  }
  .mt-md-8 {
    margin-top: 5rem !important;
  }
  .mt-md-9 {
    margin-top: 5.625rem !important;
  }
  .mt-md-10 {
    margin-top: 6.25rem !important;
  }
  .mt-md-gutter {
    margin-top: 2.133rem !important;
  }
  .mt-md-out-container {
    margin-top: calc(50% - 50vw) !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-sm {
    margin-right: 0.3125rem !important;
  }
  .me-md-3px {
    margin-right: 3px !important;
  }
  .me-md-15px {
    margin-right: 15px !important;
  }
  .me-md-25px {
    margin-right: 25px !important;
  }
  .me-md-35px {
    margin-right: 35px !important;
  }
  .me-md-65px {
    margin-right: 65px !important;
  }
  .me-md-74px {
    margin-right: 74px !important;
  }
  .me-md-700 {
    margin-right: 700px !important;
  }
  .me-md-n15 {
    margin-right: -15px !important;
  }
  .me-md-n1 {
    margin-right: -10px !important;
  }
  .me-md-n5 {
    margin-right: -5px !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.625rem !important;
  }
  .me-md-2 {
    margin-right: 1.25rem !important;
  }
  .me-md-3 {
    margin-right: 1.875rem !important;
  }
  .me-md-4 {
    margin-right: 2.5rem !important;
  }
  .me-md-5 {
    margin-right: 3.125rem !important;
  }
  .me-md-6 {
    margin-right: 3.75rem !important;
  }
  .me-md-7 {
    margin-right: 4.375rem !important;
  }
  .me-md-8 {
    margin-right: 5rem !important;
  }
  .me-md-9 {
    margin-right: 5.625rem !important;
  }
  .me-md-10 {
    margin-right: 6.25rem !important;
  }
  .me-md-gutter {
    margin-right: 2.133rem !important;
  }
  .me-md-out-container {
    margin-right: calc(50% - 50vw) !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-sm {
    margin-bottom: 0.3125rem !important;
  }
  .mb-md-3px {
    margin-bottom: 3px !important;
  }
  .mb-md-15px {
    margin-bottom: 15px !important;
  }
  .mb-md-25px {
    margin-bottom: 25px !important;
  }
  .mb-md-35px {
    margin-bottom: 35px !important;
  }
  .mb-md-65px {
    margin-bottom: 65px !important;
  }
  .mb-md-74px {
    margin-bottom: 74px !important;
  }
  .mb-md-700 {
    margin-bottom: 700px !important;
  }
  .mb-md-n15 {
    margin-bottom: -15px !important;
  }
  .mb-md-n1 {
    margin-bottom: -10px !important;
  }
  .mb-md-n5 {
    margin-bottom: -5px !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.625rem !important;
  }
  .mb-md-2 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-4 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3.125rem !important;
  }
  .mb-md-6 {
    margin-bottom: 3.75rem !important;
  }
  .mb-md-7 {
    margin-bottom: 4.375rem !important;
  }
  .mb-md-8 {
    margin-bottom: 5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 5.625rem !important;
  }
  .mb-md-10 {
    margin-bottom: 6.25rem !important;
  }
  .mb-md-gutter {
    margin-bottom: 2.133rem !important;
  }
  .mb-md-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-sm {
    margin-left: 0.3125rem !important;
  }
  .ms-md-3px {
    margin-left: 3px !important;
  }
  .ms-md-15px {
    margin-left: 15px !important;
  }
  .ms-md-25px {
    margin-left: 25px !important;
  }
  .ms-md-35px {
    margin-left: 35px !important;
  }
  .ms-md-65px {
    margin-left: 65px !important;
  }
  .ms-md-74px {
    margin-left: 74px !important;
  }
  .ms-md-700 {
    margin-left: 700px !important;
  }
  .ms-md-n15 {
    margin-left: -15px !important;
  }
  .ms-md-n1 {
    margin-left: -10px !important;
  }
  .ms-md-n5 {
    margin-left: -5px !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.625rem !important;
  }
  .ms-md-2 {
    margin-left: 1.25rem !important;
  }
  .ms-md-3 {
    margin-left: 1.875rem !important;
  }
  .ms-md-4 {
    margin-left: 2.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3.125rem !important;
  }
  .ms-md-6 {
    margin-left: 3.75rem !important;
  }
  .ms-md-7 {
    margin-left: 4.375rem !important;
  }
  .ms-md-8 {
    margin-left: 5rem !important;
  }
  .ms-md-9 {
    margin-left: 5.625rem !important;
  }
  .ms-md-10 {
    margin-left: 6.25rem !important;
  }
  .ms-md-gutter {
    margin-left: 2.133rem !important;
  }
  .ms-md-out-container {
    margin-left: calc(50% - 50vw) !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-sm {
    padding: 0.3125rem !important;
  }
  .p-md-3px {
    padding: 3px !important;
  }
  .p-md-15px {
    padding: 15px !important;
  }
  .p-md-25px {
    padding: 25px !important;
  }
  .p-md-35px {
    padding: 35px !important;
  }
  .p-md-65px {
    padding: 65px !important;
  }
  .p-md-74px {
    padding: 74px !important;
  }
  .p-md-700 {
    padding: 700px !important;
  }
  .p-md-n15 {
    padding: -15px !important;
  }
  .p-md-n1 {
    padding: -10px !important;
  }
  .p-md-n5 {
    padding: -5px !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.625rem !important;
  }
  .p-md-2 {
    padding: 1.25rem !important;
  }
  .p-md-3 {
    padding: 1.875rem !important;
  }
  .p-md-4 {
    padding: 2.5rem !important;
  }
  .p-md-5 {
    padding: 3.125rem !important;
  }
  .p-md-6 {
    padding: 3.75rem !important;
  }
  .p-md-7 {
    padding: 4.375rem !important;
  }
  .p-md-8 {
    padding: 5rem !important;
  }
  .p-md-9 {
    padding: 5.625rem !important;
  }
  .p-md-10 {
    padding: 6.25rem !important;
  }
  .p-md-gutter {
    padding: 2.133rem !important;
  }
  .p-md-out-container {
    padding: calc(50% - 50vw) !important;
  }
  .px-md-sm {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-md-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-md-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-md-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-md-35px {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
  .px-md-65px {
    padding-right: 65px !important;
    padding-left: 65px !important;
  }
  .px-md-74px {
    padding-right: 74px !important;
    padding-left: 74px !important;
  }
  .px-md-700 {
    padding-right: 700px !important;
    padding-left: 700px !important;
  }
  .px-md-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }
  .px-md-n1 {
    padding-right: -10px !important;
    padding-left: -10px !important;
  }
  .px-md-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-md-2 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-3 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-4 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-5 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-md-6 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-md-7 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-md-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-9 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-md-10 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-md-gutter {
    padding-right: 2.133rem !important;
    padding-left: 2.133rem !important;
  }
  .px-md-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }
  .py-md-sm {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-md-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-md-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-md-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-md-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .py-md-65px {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .py-md-74px {
    padding-top: 74px !important;
    padding-bottom: 74px !important;
  }
  .py-md-700 {
    padding-top: 700px !important;
    padding-bottom: 700px !important;
  }
  .py-md-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }
  .py-md-n1 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .py-md-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-md-2 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-3 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-5 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-md-6 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-md-7 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-md-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-9 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-md-10 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-md-gutter {
    padding-top: 2.133rem !important;
    padding-bottom: 2.133rem !important;
  }
  .py-md-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }
  .pt-md-sm {
    padding-top: 0.3125rem !important;
  }
  .pt-md-3px {
    padding-top: 3px !important;
  }
  .pt-md-15px {
    padding-top: 15px !important;
  }
  .pt-md-25px {
    padding-top: 25px !important;
  }
  .pt-md-35px {
    padding-top: 35px !important;
  }
  .pt-md-65px {
    padding-top: 65px !important;
  }
  .pt-md-74px {
    padding-top: 74px !important;
  }
  .pt-md-700 {
    padding-top: 700px !important;
  }
  .pt-md-n15 {
    padding-top: -15px !important;
  }
  .pt-md-n1 {
    padding-top: -10px !important;
  }
  .pt-md-n5 {
    padding-top: -5px !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.625rem !important;
  }
  .pt-md-2 {
    padding-top: 1.25rem !important;
  }
  .pt-md-3 {
    padding-top: 1.875rem !important;
  }
  .pt-md-4 {
    padding-top: 2.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3.125rem !important;
  }
  .pt-md-6 {
    padding-top: 3.75rem !important;
  }
  .pt-md-7 {
    padding-top: 4.375rem !important;
  }
  .pt-md-8 {
    padding-top: 5rem !important;
  }
  .pt-md-9 {
    padding-top: 5.625rem !important;
  }
  .pt-md-10 {
    padding-top: 6.25rem !important;
  }
  .pt-md-gutter {
    padding-top: 2.133rem !important;
  }
  .pt-md-out-container {
    padding-top: calc(50% - 50vw) !important;
  }
  .pe-md-sm {
    padding-right: 0.3125rem !important;
  }
  .pe-md-3px {
    padding-right: 3px !important;
  }
  .pe-md-15px {
    padding-right: 15px !important;
  }
  .pe-md-25px {
    padding-right: 25px !important;
  }
  .pe-md-35px {
    padding-right: 35px !important;
  }
  .pe-md-65px {
    padding-right: 65px !important;
  }
  .pe-md-74px {
    padding-right: 74px !important;
  }
  .pe-md-700 {
    padding-right: 700px !important;
  }
  .pe-md-n15 {
    padding-right: -15px !important;
  }
  .pe-md-n1 {
    padding-right: -10px !important;
  }
  .pe-md-n5 {
    padding-right: -5px !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.625rem !important;
  }
  .pe-md-2 {
    padding-right: 1.25rem !important;
  }
  .pe-md-3 {
    padding-right: 1.875rem !important;
  }
  .pe-md-4 {
    padding-right: 2.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3.125rem !important;
  }
  .pe-md-6 {
    padding-right: 3.75rem !important;
  }
  .pe-md-7 {
    padding-right: 4.375rem !important;
  }
  .pe-md-8 {
    padding-right: 5rem !important;
  }
  .pe-md-9 {
    padding-right: 5.625rem !important;
  }
  .pe-md-10 {
    padding-right: 6.25rem !important;
  }
  .pe-md-gutter {
    padding-right: 2.133rem !important;
  }
  .pe-md-out-container {
    padding-right: calc(50% - 50vw) !important;
  }
  .pb-md-sm {
    padding-bottom: 0.3125rem !important;
  }
  .pb-md-3px {
    padding-bottom: 3px !important;
  }
  .pb-md-15px {
    padding-bottom: 15px !important;
  }
  .pb-md-25px {
    padding-bottom: 25px !important;
  }
  .pb-md-35px {
    padding-bottom: 35px !important;
  }
  .pb-md-65px {
    padding-bottom: 65px !important;
  }
  .pb-md-74px {
    padding-bottom: 74px !important;
  }
  .pb-md-700 {
    padding-bottom: 700px !important;
  }
  .pb-md-n15 {
    padding-bottom: -15px !important;
  }
  .pb-md-n1 {
    padding-bottom: -10px !important;
  }
  .pb-md-n5 {
    padding-bottom: -5px !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.625rem !important;
  }
  .pb-md-2 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-4 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3.125rem !important;
  }
  .pb-md-6 {
    padding-bottom: 3.75rem !important;
  }
  .pb-md-7 {
    padding-bottom: 4.375rem !important;
  }
  .pb-md-8 {
    padding-bottom: 5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 5.625rem !important;
  }
  .pb-md-10 {
    padding-bottom: 6.25rem !important;
  }
  .pb-md-gutter {
    padding-bottom: 2.133rem !important;
  }
  .pb-md-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }
  .ps-md-sm {
    padding-left: 0.3125rem !important;
  }
  .ps-md-3px {
    padding-left: 3px !important;
  }
  .ps-md-15px {
    padding-left: 15px !important;
  }
  .ps-md-25px {
    padding-left: 25px !important;
  }
  .ps-md-35px {
    padding-left: 35px !important;
  }
  .ps-md-65px {
    padding-left: 65px !important;
  }
  .ps-md-74px {
    padding-left: 74px !important;
  }
  .ps-md-700 {
    padding-left: 700px !important;
  }
  .ps-md-n15 {
    padding-left: -15px !important;
  }
  .ps-md-n1 {
    padding-left: -10px !important;
  }
  .ps-md-n5 {
    padding-left: -5px !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.625rem !important;
  }
  .ps-md-2 {
    padding-left: 1.25rem !important;
  }
  .ps-md-3 {
    padding-left: 1.875rem !important;
  }
  .ps-md-4 {
    padding-left: 2.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3.125rem !important;
  }
  .ps-md-6 {
    padding-left: 3.75rem !important;
  }
  .ps-md-7 {
    padding-left: 4.375rem !important;
  }
  .ps-md-8 {
    padding-left: 5rem !important;
  }
  .ps-md-9 {
    padding-left: 5.625rem !important;
  }
  .ps-md-10 {
    padding-left: 6.25rem !important;
  }
  .ps-md-gutter {
    padding-left: 2.133rem !important;
  }
  .ps-md-out-container {
    padding-left: calc(50% - 50vw) !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-sm {
    gap: 0.3125rem !important;
  }
  .gap-md-3px {
    gap: 3px !important;
  }
  .gap-md-15px {
    gap: 15px !important;
  }
  .gap-md-25px {
    gap: 25px !important;
  }
  .gap-md-35px {
    gap: 35px !important;
  }
  .gap-md-65px {
    gap: 65px !important;
  }
  .gap-md-74px {
    gap: 74px !important;
  }
  .gap-md-700 {
    gap: 700px !important;
  }
  .gap-md-n15 {
    gap: -15px !important;
  }
  .gap-md-n1 {
    gap: -10px !important;
  }
  .gap-md-n5 {
    gap: -5px !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.625rem !important;
  }
  .gap-md-2 {
    gap: 1.25rem !important;
  }
  .gap-md-3 {
    gap: 1.875rem !important;
  }
  .gap-md-4 {
    gap: 2.5rem !important;
  }
  .gap-md-5 {
    gap: 3.125rem !important;
  }
  .gap-md-6 {
    gap: 3.75rem !important;
  }
  .gap-md-7 {
    gap: 4.375rem !important;
  }
  .gap-md-8 {
    gap: 5rem !important;
  }
  .gap-md-9 {
    gap: 5.625rem !important;
  }
  .gap-md-10 {
    gap: 6.25rem !important;
  }
  .gap-md-gutter {
    gap: 2.133rem !important;
  }
  .gap-md-out-container {
    gap: calc(50% - 50vw) !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .vh-md-30 {
    height: 30vh !important;
  }
  .vh-md-40 {
    height: 40vh !important;
  }
  .vh-md-50 {
    height: 50vh !important;
  }
  .vh-md-60 {
    height: 60vh !important;
  }
  .vh-md-65 {
    height: 65vh !important;
  }
  .vh-md-70 {
    height: 70vh !important;
  }
  .vh-md-80 {
    height: 80vh !important;
  }
  .vh-md-100 {
    height: 100vh !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-85 {
    width: 85% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-20px {
    width: 20px !important;
  }
  .w-md-80px {
    width: 80px !important;
  }
  .w-md-180 {
    width: 180px !important;
  }
  .w-md-200px {
    width: 200px !important;
  }
  .w-md-cal200 {
    width: calc(100% - 200px) !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-20px {
    height: 20px !important;
  }
  .h-md-27px {
    height: 27px !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .h-md-100vh {
    height: 100vh !important;
  }
  .h-md-300px {
    height: 300px !important;
  }
  .h-md-400px {
    height: 400px !important;
  }
  .h-md-600px {
    height: 600px !important;
  }
  .h-md-750px {
    height: 750px !important;
  }
  .fs-md-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-md-2 {
    font-size: calc(1.34375rem + 1.125vw) !important;
  }
  .fs-md-3 {
    font-size: calc(1.2875rem + 0.45vw) !important;
  }
  .fs-md-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-md-5 {
    font-size: calc(1.2625rem + 0.15vw) !important;
  }
  .fs-md-6 {
    font-size: 1.125rem !important;
  }
  .fs-md-7 {
    font-size: 1rem !important;
  }
  .fs-md-xl {
    font-size: calc(1.5rem + 3vw) !important;
  }
  .fs-md-lg {
    font-size: calc(1.3125rem + 0.75vw) !important;
  }
  .fs-md-md {
    font-size: calc(1.35rem + 1.2vw) !important;
  }
  .fs-md-sm {
    font-size: 0.875rem !important;
  }
  .fs-md-xs {
    font-size: 0.75rem !important;
  }
  .fs-md-xxs {
    font-size: 0.625rem !important;
  }
  .fs-md-15 {
    font-size: 0.9375rem !important;
  }
  .fs-md-12 {
    font-size: 0.75rem !important;
  }
  .fs-md-25 {
    font-size: calc(1.28125rem + 0.375vw) !important;
  }
  .fs-md-30 {
    font-size: calc(1.3125rem + 0.75vw) !important;
  }
  .fs-md-55 {
    font-size: calc(1.46875rem + 2.625vw) !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .order-md-unset {
    order: unset !important;
  }
  .cursor-md-auto {
    cursor: auto !important;
  }
  .cursor-md-pointer {
    cursor: pointer !important;
  }
  .cursor-md-grab {
    cursor: grab !important;
  }
  .border-md {
    border: 1px solid #5a5a5a !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-top-md {
    border-top: 1px solid #5a5a5a !important;
  }
  .border-top-md-0 {
    border-top: 0 !important;
  }
  .border-start-md {
    border-left: 1px solid #5a5a5a !important;
  }
  .border-start-md-0 {
    border-left: 0 !important;
  }
  .border-end-md {
    border-right: 1px solid #5a5a5a !important;
  }
  .border-end-md-0 {
    border-right: 0 !important;
  }
  .border-bottom-md {
    border-bottom: 1px solid #5a5a5a !important;
  }
  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
  .shadow-md {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16) !important;
  }
  .shadow-md-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-md-lg {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
  }
  .shadow-md-none {
    box-shadow: none !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .text-decoration-md-none {
    text-decoration: none !important;
  }
  .text-decoration-md-underline {
    text-decoration: underline !important;
  }
  .text-decoration-md-line-through {
    text-decoration: line-through !important;
  }
  .text-md-lowercase {
    text-transform: lowercase !important;
  }
  .text-md-uppercase {
    text-transform: uppercase !important;
  }
  .text-md-capitalize {
    text-transform: capitalize !important;
  }
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
  .top-md-0 {
    top: 0 !important;
  }
  .top-md-25 {
    top: 25% !important;
  }
  .top-md-50 {
    top: 50% !important;
  }
  .top-md-75 {
    top: 75% !important;
  }
  .top-md-100 {
    top: 100% !important;
  }
  .top-md-10 {
    top: 10px !important;
  }
  .top-md-20 {
    top: 20px !important;
  }
  .top-md-30 {
    top: 30px !important;
  }
  .top-md-n10 {
    top: -10px !important;
  }
  .top-md-n20 {
    top: -20px !important;
  }
  .top-md-n8 {
    top: -8px !important;
  }
  .top-md-7 {
    top: 7px !important;
  }
  .top-md-13 {
    top: 13px !important;
  }
  .top-md-15 {
    top: 15px !important;
  }
  .top-md-n3 {
    top: -3px !important;
  }
  .top-md-5 {
    top: 5px !important;
  }
  .top-md-2 {
    top: 2px !important;
  }
  .bottom-md-0 {
    bottom: 0 !important;
  }
  .bottom-md-25 {
    bottom: 25% !important;
  }
  .bottom-md-50 {
    bottom: 50% !important;
  }
  .bottom-md-75 {
    bottom: 75% !important;
  }
  .bottom-md-100 {
    bottom: 100% !important;
  }
  .bottom-md-10 {
    bottom: 10px !important;
  }
  .bottom-md-20 {
    bottom: 20px !important;
  }
  .bottom-md-30 {
    bottom: 30px !important;
  }
  .bottom-md-n10 {
    bottom: -10px !important;
  }
  .bottom-md-n20 {
    bottom: -20px !important;
  }
  .bottom-md-n8 {
    bottom: -8px !important;
  }
  .bottom-md-7 {
    bottom: 7px !important;
  }
  .bottom-md-13 {
    bottom: 13px !important;
  }
  .bottom-md-15 {
    bottom: 15px !important;
  }
  .bottom-md-n3 {
    bottom: -3px !important;
  }
  .bottom-md-5 {
    bottom: 5px !important;
  }
  .bottom-md-2 {
    bottom: 2px !important;
  }
  .end-md-0 {
    right: 0 !important;
  }
  .end-md-25 {
    right: 25% !important;
  }
  .end-md-50 {
    right: 50% !important;
  }
  .end-md-75 {
    right: 75% !important;
  }
  .end-md-100 {
    right: 100% !important;
  }
  .end-md-10 {
    right: 10px !important;
  }
  .end-md-20 {
    right: 20px !important;
  }
  .end-md-30 {
    right: 30px !important;
  }
  .end-md-n10 {
    right: -10px !important;
  }
  .end-md-n20 {
    right: -20px !important;
  }
  .end-md-n8 {
    right: -8px !important;
  }
  .end-md-7 {
    right: 7px !important;
  }
  .end-md-13 {
    right: 13px !important;
  }
  .end-md-15 {
    right: 15px !important;
  }
  .end-md-n3 {
    right: -3px !important;
  }
  .end-md-5 {
    right: 5px !important;
  }
  .end-md-2 {
    right: 2px !important;
  }
  .translate-middle-md {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-md-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-md-y {
    transform: translateY(-50%) !important;
  }
}


@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}


@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}